<template>
    <div>
        <b-dropdown id="dropdown-form" right ref="dropdown" :variant="computeButtonVariant" class="ml-2 ml-sm-3 ml-lg-4" no-caret>
            <template #button-content>
                <div class="wh-40-img rounded bg-cover-center bg-gray-100s d-flex justify-content-center text-black-50">
                    <i class="ri-user-4-line ri-lg"></i>
                </div>
                <span class="d-none d-md-inline" v-if="nameSurname">{{ nameSurname }}</span>
            </template>
            <b-dropdown-item  v-if="isLogin"
                              @click="logout"
                            >
                <span class="text-danger">
                    <i class="ri-logout-circle-line mr-3 top-minus-1 align-middle"></i>{{ $t('logout') }}
                </span>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
    import {localeChanged} from 'vee-validate';

    export default {
        props: {
            nameSurname: {
                type: String,
                default: null
            },
            isLogin: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            activeLocale() {
                return this.$i18n.locale;
            },
            computeButtonVariant(){
                if(this.isLogin) return 'outline-secondary pl-3 pl-md-0 w-40 w-min-md-100 no-transition';
                            else return 'outline-secondary w-40';
            }
        },
        methods: {
            changeLocale(locale) {
                this.$i18n.locale = locale;
                localeChanged();
            },
            logout() {
                this.$emit('epaymentLogout');
            }
        },
    };
</script>
